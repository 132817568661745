import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService, ToasterService } from 'src/app/core/core.index';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toasterService: ToasterService, private auth: AuthService,) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // return next.handle(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // App side error
        if (error.error instanceof ErrorEvent) {

          // display error message 
          this.toasterService.typeError(error.error.message, "Error!");
        }
        // Server side error
        else {
          if (error.status === 500) {
            //  redirect to error page 
            this.toasterService.typeError('Internal server error!', "Error!");
          } else {
            // display error message
            if (error.error.message) {
              if (error.error.message.message === 'Session expired or not found.Please Login Again' || error.error.message.message === 'Session expired due to inactivity.Please Login Again') {
                this.toasterService.typeError(error.error.message.message, "Error!");
                this.auth.logout();
              } else {
                this.toasterService.typeError(error.error.message, "Error!");
              }
            } else {
              this.toasterService.typeError(error.error.error, "Error!");
            }
          }
        }
        // Server stopped error
        if (error.status === 0) {
          this.toasterService.typeError('Somthing went wrong!', "Error!");
        }
        return throwError(error.error.message);

      })
    );
  }
}
